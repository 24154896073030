import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import config from '../../config/config';
import { fetchBidsByProject } from '../bids/bidsSlice';

interface HireState {
    hireStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    successMessage: string | null;
    hireError: string | { message: string } | null;
}

const initialState: HireState = {
    hireStatus: 'idle',
    hireError: null,
    successMessage: null,
};

export const hireBid = createAsyncThunk(
    'hire/hireBid',
    async (
        { projectId, bidId }: { projectId: number; bidId: number; },
        { dispatch, rejectWithValue }
    ) => {
        try {
            const response = await axios.post(
                `${config.apiBaseUrl}/projects/${projectId}/hire`,
                {
                    bidId,
                    projectId,
                    currency: 'nzd',
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            // Refresh bids after hiring
            dispatch(fetchBidsByProject(projectId));

            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            return rejectWithValue(axiosError.response?.data || 'An error occurred');
        }
    }
);

const hireSlice = createSlice({
    name: 'hire',
    initialState,
    reducers: {
        clearSuccessMessage: (state) => {
            state.successMessage = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(hireBid.pending, (state) => {
                state.hireStatus = 'loading';
                state.hireError = null;
            })
            .addCase(hireBid.fulfilled, (state) => {
                state.hireStatus = 'succeeded';
                state.successMessage = 'Bid hired successfully!';
            })
            .addCase(hireBid.rejected, (state, action) => {
                state.hireStatus = 'failed';
                state.hireError = action.payload as string;
            });
    },
});

export const { clearSuccessMessage } = hireSlice.actions;
export default hireSlice.reducer;
