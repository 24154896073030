import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { hireBid, clearSuccessMessage } from '../../features/hiring/hireSlice';
import { fetchBidsByProject } from '../../features/bids/bidsSlice';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

interface Bidder {
    id: number;
    username: string;
    email: string;
    contactNumber: string;
    businessDetails: {
        legalBusinessName: string;
        nzbn: string;
    };
}

interface Bid {
    id: number;
    description: string;
    price: number;
    currency: string;
    quantity: number;
    totalAmount: number;
    gst: number;
    projectId: number;
    userId: number;
    createdAt: string;
    updatedAt: string;
    bidder: Bidder;
}

interface HireNowProps {
    isOpen: boolean;
    onClose: () => void;
    bid: Bid;
}

const HireNow: React.FC<HireNowProps> = ({ isOpen, onClose, bid }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { hireStatus, successMessage } = useSelector((state: RootState) => state.hire);

    useEffect(() => {
        if (successMessage) {
            setTimeout(() => dispatch(clearSuccessMessage()), 10000);
        }
    }, [successMessage, dispatch]);


    const handleCloseDialog = () => {
        onClose();
        dispatch(fetchBidsByProject(bid.projectId)); // Refresh bids when the dialog closes
    };

    const handleSubmitPayment = async () => {


        await dispatch(
            hireBid({
                projectId: bid.projectId,
                bidId: bid.id
            })
        );
        window.location.reload();
    };

    return (
        <Dialog open={isOpen} onClose={handleCloseDialog} className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
            <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                <XMarkIcon onClick={handleCloseDialog} className="absolute w-4 cursor-pointer right-8" />

                {successMessage ? (
                    <div className="text-center">
                        <h2 className="text-xl font-extrabold mb-2">{successMessage}</h2>
                        <button
                            onClick={handleCloseDialog}
                            className="mt-4 px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                        >
                            Close
                        </button>
                    </div>
                ) : (
                    <div className="text-left">
                        <h2 className="text-xl font-extrabold mb-2">Hire {bid.bidder.businessDetails.legalBusinessName}</h2>
                        <p className="font-semibold">Project amount: ${bid.totalAmount}</p>

                        <div className="mt-4">
                            <p className="font-semibold">Are you sure you want to hire ? </p>
                        </div>

                        <div className="space-y-4 pt-6">


                            <div className="flex justify-between space-x-4">
                                <button
                                    onClick={() => onClose()}
                                    className="w-full px-8 py-3 text-gray-600 font-bold rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                >
                                    No
                                </button>
                                <button
                                    onClick={handleSubmitPayment}
                                    disabled={hireStatus === 'loading'}
                                    className="w-full px-8 py-3 text-white font-bold rounded-lg bg-primary hover:bg-primary-dark focus:outline-none"
                                >
                                    {hireStatus === 'loading' ? 'Paying...' : 'Yes'}
                                </button>
                            </div>
                        </div>

                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default HireNow;
