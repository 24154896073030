import React from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface ContactViewProps {
    isOpen: boolean;
    onClose: () => void;
    bidder: Bidder;
}

interface Bidder {
    id: number;
    username: string;
    email: string;
    contactNumber: string;
    rating: number;
    businessDetails: {
        legalBusinessName: string;
        nzbn: string;
    };
}

const ContactView: React.FC<ContactViewProps> = ({ isOpen, onClose, bidder }) => {
    const handleCloseDialog = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleCloseDialog}
            className="fixed inset-0 z-50 flex items-center justify-center"
        >
            <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
            <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                <XMarkIcon
                    onClick={handleCloseDialog}
                    className="absolute w-4 cursor-pointer right-8"
                />

                <div className="text-left">
                    <h2 className="text-xl font-extrabold mb-6">Bussinness Details:</h2>
                    <p className="font-semibold mb-2">Username: <span className="font-normal">{bidder.username}</span></p>
                    <p className="font-semibold mb-2">Phone: <span className="font-normal">{bidder.contactNumber}</span></p>
                    <p className="font-semibold mb-2">Email: <span className="font-normal">{bidder.email}</span></p>
                    <p className="font-semibold mb-2">Legal Business Name: <span className="font-normal">{bidder.businessDetails.legalBusinessName}</span></p>


                    <div className="space-y-4 pt-6">
                        <div className="flex justify-between space-x-4">
                            <button
                                onClick={handleCloseDialog}
                                className="w-full px-8 py-3 text-gray-600 font-bold rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ContactView;